const docs = {
  subjects: "subjects",
  sections: "sections",
  teachers: "teachers",
  lectures: "lectures",
  workingTime: "workingTime",
  timeTable: "timeTable",
};

export default docs;
